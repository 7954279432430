// (function( $ ) {

// 	"use strict";
	
// 	$(document).ready( function(){
	   
//         0 < $('img[src$=".svg"].curve-img').length &&
//         $(function () {
//             $('img[src$=".svg"]').each(function () {
//                 var t = jQuery(this),
//                 e = t.attr("id"),
//                 i = t.attr("class"),
//                 n = t.attr("src");
//                 jQuery.get(
//                     n,
//                     function (n) {
//                         (n = jQuery(n).find("svg")), void 0 !== e && (n = n.attr("id", e)), (n = (n = void 0 !== i ? 
//                         n.attr("class", i + " replaced-svg") 
//                         n.attr("width", "100%") 
//                         n.attr("height", "auto") : n).removeAttr("xmlns:a")), t.replaceWith(n);
//                     },
//                     "xml"
//                 );
//             });
//             var svgImages = document.querySelectorAll('svg.curve-img');
//             console.log(svgImages.length);
//             if ( svgImages.length > 0 ) {
//                 console.log("yes");
//                 svgImages.forEach( ( svg, i ) => {
//                     svg.setAttribute('width', '100%');
//                     svg.setAttribute('height', '100%');
//                 } );
//             }
//         });
    
//     } ); 
	
// })(jQuery);

const convertImages = (query) => {
    const images = document.querySelectorAll(query);
    
    images.forEach(image => {
        fetch(image.src)
        .then(res => res.text())
        .then(data => {
            const parser = new DOMParser();
            const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');
            
            if (image.id) {
                svg.id = image.id;
            }
            if (image.className) {
                svg.classList = image.classList;
                svg.setAttribute('width', '100%');
                svg.setAttribute('height', 'auto');
            }
            image.parentNode.replaceChild(svg, image);
        })
        // .then(callback)
        .catch(error => console.error(error))
    });
}

convertImages('.curve-img');