import PhotoSwipeLightbox from 'photoswipe/lightbox';
// import 'photoswipe/style.css';

const lightbox = new PhotoSwipeLightbox({
  gallery: '.gallery-row',
  children: 'a',
  pswpModule: () => import('photoswipe')
});
lightbox.init();

const buildLightbox = ( galleryElm, index ) => {
    galleryElm.classList.add(`lightbox-gallery-${index}`);
    
    const lightbox = new PhotoSwipeLightbox({
        gallery: `lightbox-gallery-${index}`,
        children: 'a',
        pswpModule: () => import('photoswipe')
    });
    lightbox.init();
}

document.addEventListener("DOMContentLoaded", function () {
    // Get all of the swipers on the page
    const allGalleries = document.querySelectorAll('.gallery-row');
    
    // Loop over all of the fetched sliders and apply Swiper on each one.
    allGalleries.forEach( ( gallery, i ) => buildLightbox( gallery, i ) );
});