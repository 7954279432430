(function( $ ) {

	"use strict";
	
	// javascript code here. i.e.: 
	$(document).ready( function(){
	    var currentSlideT,
            lastSlideT,
            nextQuoteT,
            slides,
            prevQuoteT;
        
        var slides = $('.testimonial-image-wrap').children('.testimonial-image-item');
        
        slides.first().addClass('is-active');
        slides.first().next().addClass('next');
        $('.testimonial-image-item.next').next().addClass('next-2');
        $('.next-2').nextAll().not(':last').addClass('in-active');
        slides.last().addClass('last');
        
        $('.is-active').css({
          "left":"0",
          "opacity": "1",
          "visibility": "visible",
          "z-index": "2",
        })
        $('.testimonial-image-item.next').css({
          "left":"calc(100% + 34px)",
          "opacity": "0.5",
          "visibility": "inherit",
          "transform": "translateY(-50%) scale(0.75)",
        })
        $('.next-2').css({
          "left":"calc(185% + 34px)",
          "opacity": "0.5",
          "visibility": "inherit",
          "transform": "translateY(-50%) scale(0.50)",
        })
        $('.in-active').css({
          "left":"calc(255%)",
          "opacity": "0",
          "visibility": "inherit",
          "transform": "translateY(-50%) scale(0.50)",
        })
        $('.last').css({
          "left":"0",
          "opacity": "0",
          "visibility": "inherit",
          "transform": "translateY(-50%) scale(0.50)",
        })
        
        // Next Button
        $(".control-next").on("click", (e) => {
            
          var nextSlide,
              currentSlide,
              nextSlide2,
              lastSlide,
              prevSlide,
              nextQuote,
              currentQuote;
          
        //   disbale controls
          $(".control-next").css({"pointer-events":"none"});
          $(".control-prev").css({"pointer-events":"none"});
          
        //   Clearing timeouts
          clearTimeout(currentSlideT);
          clearTimeout(lastSlideT);
          clearTimeout(nextQuoteT);
          
        //   Quote
          if($('.quote--is-active').is(':last-child')) {
            nextQuote = $('.quote--is-active').parent().children().first();
          } else {
            nextQuote = $('.quote--is-active').next();
          }
          if( nextQuote.is( ':first-child' ) ) {
            currentQuote = nextQuote.parent().children().last();
          } else {
            currentQuote = nextQuote.prev();
          }
          currentQuote.removeClass('quote--is-active').css({"transform":"translateY(-5%)", "opacity": 0, "transition-duration":"0.75s"});
          nextQuote.addClass('quote--is-active').css({"transform":"translateY(5%)", "opacity": 0, "transition-duration":"0s"});
          var nextQuoteT = setTimeout(()=> {
            nextQuote.css({"transform":"translateY(0)", "opacity":1, "transition-duration":"0.75s"});
          }, 700);
          
        //   Images
          if($('.is-active').is( ':last-child' )) {
            nextSlide = $('.is-active').parent().children().first();
          } else {
            nextSlide = $('.is-active').next();
          }
          if( nextSlide.is( ':first-child' ) ) {
            currentSlide = nextSlide.parent().children().last();
          } else {
            currentSlide = nextSlide.prev();
          }
          if( nextSlide.is( ':last-child' ) ) {
            nextSlide2 = nextSlide.parent().children().first();
          } else {
            nextSlide2 = nextSlide.next();
          }
          if( nextSlide2.is(':last-child') ) {
            lastSlide = nextSlide.parent().children().first();
          } else {
            lastSlide = nextSlide2.next();
          }
          if( currentSlide.is(':first-child') ) {
            prevSlide = currentSlide.parent().children().last();
          } else {
            prevSlide = currentSlide.prev();
          }
          currentSlide.removeClass('is-active').css({"transform":"translateY(-50%) scale(0.5)", "opacity":0, "transition-duration":"1s"});
        
          nextSlide.addClass("is-active").css( { "left": 0, "transform": "translateY(-50%) scale(1)", "transition-duration":"1.5s", "opacity": 1 });
            nextSlide2.css({"transform":"translateY(-50%) scale(0.76)", "left": "calc(100% + 34px)", "opacity":0.5, "transition-duration":"1.5s"});
            lastSlide.css({"transform":"translateY(-50%) scale(0.5)", "left": "calc(255%)", "opacity":0.5, "transition-duration":"0s"});
          var lastSlideT = setTimeout(()=> {
            lastSlide.css({"transform":"translateY(-50%) scale(0.5)", "left": "calc(185% + 34px)", "opacity":0.5, "transition-duration":"1.5s"});
          }, 0.1);
          var currentSlideT = setTimeout(()=> {
            currentSlide.css({"transform":"translateY(-50%) scale(0.5)", "left": "255%", "opacity": 0, "transition-duration":"0s"});
          }, 1001);
          var nextEnableButton = setTimeout(()=> {
            $(".control-next").css({"pointer-events":"auto"});
            $(".control-prev").css({"pointer-events":"auto"});
          }, 1501);
        });
        
        // PREV Button
        $(".control-prev").on("click", () => {
          var nextSlide,
              prevSlide,
              currentSlide,
              nextSlide2,
              lastSlide,
              prevQuote,
              currentQuote;
          
        //   disable controls
          $(".control-prev").css({"pointer-events":"none"});
          $(".control-next").css({"pointer-events":"none"});
          
        //   Clear Timeouts
          clearTimeout(currentSlideT);
          clearTimeout(lastSlideT);
          clearTimeout(prevQuoteT);
          
        //   Quote
          if($('.quote--is-active').is(':first-child')) {
            prevQuote = $('.quote--is-active').parent().children().last();
          } else {
            prevQuote = $('.quote--is-active').prev();
          }
          if( prevQuote.is( ':last-child' ) ) {
            currentQuote = prevQuote.parent().children().first();
          } else {
            currentQuote = prevQuote.next();
          }
          currentQuote.removeClass('quote--is-active').css({"transform":"translateY(-5%)", "opacity": 0, "transition-duration":"0.75s"});
          prevQuote.addClass('quote--is-active').css({"transform":"translateY(5%)", "opacity": 0, "transition-duration":"0s"});
          var prevQuoteT = setTimeout(()=> {
            prevQuote.css({"transform":"translateY(0)", "opacity":1, "transition-duration":"0.75s"});
          }, 750);
          
        //   Images
          if($('.is-active').is( ':last-child' )) {
            nextSlide = $('.is-active').parent().children().first();
          } else {
            nextSlide = $('.is-active').next();
          }
          if( nextSlide.is( ':first-child' ) ) {
            currentSlide = nextSlide.parent().children().last();
          } else {
            currentSlide = nextSlide.prev();
          }
          if( nextSlide.is( ':last-child' ) ) {
            nextSlide2 = nextSlide.parent().children().first();
          } else {
            nextSlide2 = nextSlide.next(); 
          }
          if( nextSlide2.is(':last-child') ) {
            lastSlide = nextSlide.parent().children().first().next();
          } else {
            lastSlide = nextSlide2.next();
          }
           if( currentSlide.is(':first-child') ) {
            prevSlide = currentSlide.parent().children().last();
          } else {
            prevSlide = currentSlide.prev();
          }
          
          currentSlide.removeClass('is-active').css({"transform":"translateY(-50%) scale(0.76)", "left": "calc(100% + 34px)", "opacity":0.5, "transition-duration":"1.5s"});
          nextSlide.css({"transform":"translateY(-50%) scale(0.5)", "left": "calc(185% + 36px)", "opacity":0.5, "transition-duration":"1.5s"});
          nextSlide2.css({"transform":"translateY(-50%) scale(0.5)", "left": "calc(255%)", "opacity":0, "transition-duration":"1.5s"});
        //   lastSlide.css({"transform":"translateY(-50%) scale(0.5)", "left": "270%", "opacity":0, "transition-duration":"0s"});
          prevSlide.addClass("is-active").css({"transform":"translateY(-50%) scale(0.5)", "left": "0", "opacity":0, "transition-duration":"0s"});
          var currentSlideT = setTimeout(()=> {
            prevSlide.addClass("is-active").css({"transform":"translateY(-50%) scale(1)", "left": "0", "opacity":1, "transition-duration":"1s"});
          }, 0.1);
          var prevEnableButton = setTimeout(()=> {
            $(".control-prev").css({"pointer-events":"auto"});
            $(".control-next").css({"pointer-events":"auto"});
          }, 1501);
        });
	} );

})(jQuery);