// normal input field event
var field =  document.querySelectorAll('.material-style .gform_wrapper div.gfield input, .material-style .gform_wrapper div.gfield textarea, .material-style  .gform_validation_error div.gfield input');

for (var i = 0, len = field.length; i < len; i++) {
   // add class to  parent element on focus event 
   field[i].addEventListener('focus', function () {
      this.closest(".gfield").classList.add('focused');
   })
   // add   or remove  focused   class to  parent element on blur  event 
   field[i].addEventListener('blur', function () {
      if(this !== null && this.value !== ""){
         this.closest(".gfield").classList.add('focused');
      }
     else{
         this.closest(".gfield").classList.remove('focused');
      }
   })
   // add   focused  class to parent element if the input field is not empty
   if(field[i].value !== ''){
      field[i].closest(".gfield").classList.add('focused');
   }
   else{
      field[i].closest(".gfield").classList.remove('focused');
   }
}
// complex field event 
var complexField =  document.querySelectorAll('.material-style .gform_wrapper .ginput_complex span input, .material-style .gform_wrapper .ginput_complex  .ginput_full input');
for (var i = 0, len = complexField.length; i < len; i++) {
   // add focused class to complex field   parent element on focus event 
   complexField[i].addEventListener('focus', function () {
     this.closest("span").classList.add('focused');
   })
   // add or  remove focused  class  to complex field  parent element  on blur event
   complexField[i].addEventListener('blur', function () {
        if(this !== null && this.value === ""){
            this.closest("span").classList.remove('focused');
         }else{
            this.closest("span").classList.add('focused');
         }
   })
}
// add class  to date and select field  parent element  on default state and blur event 
const dateField = document.querySelectorAll(' .material-style .gform_wrapper div.gfield input.datepicker, .material-style .gform_wrapper div.gfield select')
for (var i = 0, len = dateField.length; i < len; i++) {
   dateField[i].closest('.gfield').classList.add('focused');
   dateField[i].addEventListener('blur', function () {
      this.closest('.gfield').classList.add('focused');    
   })
}