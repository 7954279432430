import Swiper from 'swiper/bundle';

const buildSwiperSlider = ( sliderElm, index ) => {
    sliderElm.classList.add(`pakage-slider-${index}`);
    const parentElm = document.querySelector(`.pakage-slider-${index}`).closest('.section-package-slider');
    const nextButton = parentElm.querySelector('.package-slider-next');
    nextButton.classList.add(`package-slider-next-${index}`);
    return new Swiper(`.pakage-slider-${index}`, {
        autoplay: 3000,
        speed: 800,
        loop: false,  //<--- set if loop is true or not
        slidesPerView: 1.5,
        spaceBetween: 24,
        freeMode: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 1.5,
              spaceBetween: 24
            },
            // when window width is >= 480px
            768: {
              slidesPerView: 1.2,
              spaceBetween: 24
            },
            // when window width is >= 640px
            1024: {
              slidesPerView: 1.5,
              spaceBetween: 36
            },
            1200: {
                slidesPerView: 1.77,
            },
            1920: {
                slidesPerView: 2.2,
            }
        },
        navigation: {
            nextEl: `.package-slider-next-${index}`
        },
    });
}

document.addEventListener("DOMContentLoaded", function () {
    // Get all of the swipers on the page
    const packageSliders = document.querySelectorAll('.package-slider.swiper');
    
    // Loop over all of the fetched sliders and apply Swiper on each one.
    packageSliders.forEach( ( slider, i ) => buildSwiperSlider( slider, i ) );
});