import Swiper from 'swiper/bundle';

const setWrapperStyles = ( swiper ) => {
    if ( !swiper || !swiper.navigation.prevEl ) {
        return;
    }
    var wrapperCenterClass = 'swiper-wrapper--center';
    var buttonDisabledClass = swiper.params.navigation.disabledClass;
    var wrapperEl = swiper.wrapperEl;
    let prevBtn, nextBtn;
    
    if( Array.isArray( swiper.navigation.prevEl ) ) {
        prevBtn = swiper.navigation.prevEl[0];
        nextBtn = swiper.navigation.nextEl[0];
    } else {
        prevBtn = swiper.navigation.prevEl;
        nextBtn = swiper.navigation.nextEl;
    }
    if ( 
        ( 
            prevBtn.classList.contains(buttonDisabledClass)
            && nextBtn.classList.contains(buttonDisabledClass) 
        ) 
        || swiper.isLocked
    ) {
        // center slides
        wrapperEl.classList.add(wrapperCenterClass);
    } else {
        wrapperEl.classList.remove(wrapperCenterClass);
    }
}

const buildInstaSwiper = ( sliderElm, index ) => {
    if ( !sliderElm ) return;
    sliderElm.classList.add(`insta-swiper-${index}`,'swiper');
    
    const wrapper = sliderElm.querySelector('#sbi_images');
    
    if ( !wrapper ) return;
    
    wrapper.classList.add('swiper-wrapper');
    
    for (let i = 0; i < wrapper.children.length; i++) {
        var child = wrapper.children[i];
        if ( child.classList.contains( 'sbi_item' ) ) {
            child.classList.add( 'swiper-slide' );
        }
    }
    const prevButton = sliderElm.parentNode.querySelector('.insta-swiper-button-prev');
    prevButton.classList.add(`insta-swiper-button-prev-${index}`);
    const nextButton = sliderElm.parentNode.querySelector('.insta-swiper-button-next');
    nextButton.classList.add(`insta-swiper-button-next-${index}`);
    
    // const pagination = document.querySelector(`.column-swiper-with-nav-${index} .column-swiper-pagination`);
    // pagination.classList.add(`column-swiper-pagination-${index}`);
    
    return new Swiper(`.insta-swiper-${index}`, {
        autoplay: {
            disableOnInteraction: true,
            delay: 1,
            pauseOnMouseEnter: true,
        },
        mousewheel: true,
        speed: 5000,
        loop: true,  //<--- set if loop is true or not
        loopedSlides: 3,
        slidesPerView: 1.5,
        watchOverflow: true,
        centeredSlides: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 1.5,
              spaceBetween: 20
            },
            // when window width is >= 480px
            768: {
              slidesPerView: 2.5,
              spaceBetween: 36
            },
            // when window width is >= 480px
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 36
            },
        },
        // pagination: {
        //     el: `.column-swiper-pagination-${index}`,
        //     type: 'bullets',
        //     clickable: true,
        // },
        navigation: {
            nextEl: `.insta-swiper-button-next-${index}`,
            prevEl: `.insta-swiper-button-prev-${index}`,
        },
        on: {
            init: function (swiper) {
                setTimeout(function() {
                    setWrapperStyles(swiper)
                }, 200);
            },
            resize: setWrapperStyles
        },
    });
}

document.addEventListener("DOMContentLoaded", function () {
    // Get all of the swipers on the page
    const instaSliders = document.querySelectorAll('.insta-feed-wrap .sbi');
    
    // Loop over all of the fetched sliders and apply Swiper on each one.
    instaSliders.forEach( ( slider, i ) => buildInstaSwiper( slider, i ) );
    
    instaSliders.forEach( ( slider, i ) => {
        const thisSwiper = slider.swiper;
        slider.addEventListener("mouseenter", (event) => {
            thisSwiper.autoplay.stop();
        });
        slider.addEventListener("mouseleave", (event) => {
            thisSwiper.autoplay.start();
        });
        
        var prevBtn, nextBtn;
        if( Array.isArray( thisSwiper.navigation.nextEl ) ) {
            nextBtn = thisSwiper.navigation.nextEl[0];
        } else {
            nextBtn = thisSwiper.navigation.nextEl;
        }
        nextBtn.addEventListener( 'click', () => {
            thisSwiper.autoplay.stop();
        } );
    } );
});