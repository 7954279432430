import Swiper from 'swiper/bundle';

const setWrapperStyles = (swiper) => {
    if ( !swiper || !swiper.navigation.prevEl ) {
        return;
    }
    var wrapperCenterClass = 'swiper-wrapper--center';
    var buttonDisabledClass = swiper.params.navigation.disabledClass;
    var wrapperEl = swiper.wrapperEl;
    let prevBtn, nextBtn;
    
    if( Array.isArray( swiper.navigation.prevEl ) ) {
        prevBtn = swiper.navigation.prevEl[0];
        nextBtn = swiper.navigation.nextEl[0];
    } else {
        prevBtn = swiper.navigation.prevEl;
        nextBtn = swiper.navigation.nextEl;
    }
    if ( 
        ( 
            prevBtn.classList.contains(buttonDisabledClass)
            && nextBtn.classList.contains(buttonDisabledClass) 
        ) 
        || swiper.isLocked
    ) {
        // center slides
        wrapperEl.classList.add(wrapperCenterClass);
    } else {
        wrapperEl.classList.remove(wrapperCenterClass);
    }
}

const buildSwiperWithNav = ( sliderElm, index ) => {
    sliderElm.classList.add(`column-swiper-with-nav-${index}`);
    
    const nextButton = sliderElm.parentNode.querySelector('.column-swiper-button-next');
    const prevButton =  sliderElm.parentNode.querySelector('.column-swiper-button-prev');
    nextButton.classList.add(`column-swiper-button-next-${index}`);
    prevButton.classList.add(`column-swiper-button-prev-${index}`);
    
    const pagination = document.querySelector(`.column-swiper-with-nav-${index} .column-swiper-pagination`);
    pagination.classList.add(`column-swiper-pagination-${index}`);
    
    return new Swiper(`.column-swiper-with-nav-${index}`, {
        autoplay: {
            pauseOnMouseEnter: true,
            delay: 2000,
            disableOnInteraction: false,
        },
        speed: 800,
        loop: false,  //<--- set if loop is true or not
        // loopedSlides: 3,
        slidesPerView: 1,
        watchOverflow: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            // when window width is >= 480px
            768: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            // when window width is >= 640px
            1024: {
              slidesPerView: 4,
              spaceBetween: 30
            }
        },
        pagination: {
            el: `.column-swiper-pagination-${index}`,
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: `.column-swiper-button-next-${index}`,
            prevEl: `.column-swiper-button-prev-${index}`
        },
        on: {
            init: function (swiper) {
                setTimeout(function() {
                    setWrapperStyles(swiper)
                }, 200);
            },
            resize: setWrapperStyles
        },
    });
}

const buildSwiperWithoutNav = ( sliderElm, index ) => {
    sliderElm.classList.add(`column-swiper-no-nav-${index}`);
    return new Swiper(`.column-swiper-no-nav-${index}`, {
        speed: 800,
        loop: false,
        // loopedSlides: 3,
        slidesPerView: 1.4,
        centerInsufficientSlides: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 1.4,
              spaceBetween: 20
            },
            // when window width is >= 480px
            768: {
              slidesPerView: 2.4,
              spaceBetween: 24
            },
            // when window width is >= 480px
            1024: {
              slidesPerView: 3.4,
              spaceBetween: 24
            },
            // when window width is >= 480px
            1440: {
              slidesPerView: 3,
              spaceBetween: 24
            },
            1870: {
                slidesPerView: 4,
                spaceBetween: 24,
                touchReleaseOnEdges: true
            }
        },
    });
}

document.addEventListener("DOMContentLoaded", function () {
    // Get all of the swipers on the page
    const columnSlidersWithNav = document.querySelectorAll('.column-swiper.swiper.swiper-has-nav');
    const columnSlidersWithoutNav = document.querySelectorAll('.column-swiper.swiper.swiper-no-nav');
    
    // Loop over all of the fetched sliders and apply Swiper on each one.
    columnSlidersWithNav.forEach( ( slider, i ) => buildSwiperWithNav( slider, i ) );
    
    columnSlidersWithoutNav.forEach( ( slider, i ) => buildSwiperWithoutNav( slider, i ) );
});