import Swiper from 'swiper/bundle';

const buildSwiperSlider = ( sliderElm, index ) => {
    sliderElm.classList.add(`banner-swiper-${index}`);
    const parentElm = document.querySelector(`.banner-swiper-${index}`).parentElement;
    const nextButton = parentElm.querySelector('.banner-swiper-button-next');
    const prevButton = parentElm.querySelector('.banner-swiper-button-prev');
    nextButton.classList.add(`banner-swiper-button-next-${index}`);
    prevButton.classList.add(`banner-swiper-button-prev-${index}`);
    return new Swiper(`.banner-swiper-${index}`, {
        autoPlay: 2000,
        speed: 800,
        loop: true,  //<--- set if loop is true or not
        slidesPerView: 1,
        navigation: {
            nextEl: `.banner-swiper-button-next-${index}`,
            prevEl: `.banner-swiper-button-prev-${index}`
        },
    });
}

document.addEventListener("DOMContentLoaded", function () {
    // Get all of the swipers on the page
    const bannerSliders = document.querySelectorAll('.banner-swiper.swiper');
    
    // Loop over all of the fetched sliders and apply Swiper on each one.
    bannerSliders.forEach( ( slider, i ) => buildSwiperSlider( slider, i ) );
});