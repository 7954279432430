document.addEventListener("DOMContentLoaded", () => {
    setMainPaddingBottom();
    window.addEventListener("resize", setMainPaddingBottom);
    window.addEventListener("orientationchange", setMainPaddingBottom);
});
function setMainPaddingBottom() {
    var footer = document.querySelector(".site-footer"),
        main = document.querySelector(".site-main"),
        footerHeight = footer.offsetHeight;
    
    main.style.cssText = 'min-height: 100vh; min-height: 100dvh;';
    main.style.paddingBottom = footerHeight + "px";
    main.style.marginBottom = "-" + footerHeight + "px";
}